import styles from "./PageLoader.module.scss";

const PageLoader = () => {
  return (
    <div className={styles.container}>
      <div className={styles.spinner} />
    </div>
  );
};

export default PageLoader;
