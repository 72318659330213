import React, { useMemo } from "react";
import { List, Button } from "antd";
import { LoginOutlined } from "@ant-design/icons";
import { useInfiniteQuery } from "@tanstack/react-query";
import Link from "next/link";

import api from "@/utils/api";
import { CompaniesResponse } from "@/utils/types/response/company";
import { defaultPageSize } from "@/utils/constants";

import FetchContainer from "@/components/FetchContainer/FetchContainer";

const CompaniesSmall = () => {
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isLoadingError,
    isLoading,
    refetch,
  } = useInfiniteQuery<CompaniesResponse>({
    queryKey: ["companies"],
    initialPageParam: 1,
    queryFn: ({ pageParam = 1 }) =>
      api.company.getCompanies(pageParam as number, defaultPageSize),
    getNextPageParam: (lastPage) =>
      lastPage.hasNextPage ? lastPage.pageNumber + 1 : undefined,
  });

  const companies = useMemo(
    () => data?.pages.flatMap((page) => page.data),
    [data],
  );

  const loadMore = hasNextPage ? (
    <div
      style={{
        textAlign: "center",
        marginTop: 20,
      }}
    >
      <Button onClick={() => fetchNextPage()}>Load More</Button>
    </div>
  ) : null;

  return (
    <FetchContainer
      isPending={isLoading}
      isError={isLoadingError}
      noData={!companies?.length}
      retry={() => refetch()}
    >
      <List
        itemLayout="horizontal"
        loadMore={loadMore}
        dataSource={companies}
        renderItem={(company) => (
          <List.Item
            actions={[
              <Link
                href={`/${company.id}/dashboard`}
                passHref
                onClick={(e) => e.stopPropagation()}
                key={company.id}
              >
                <Button type="link" icon={<LoginOutlined />} />
              </Link>,
            ]}
          >
            <List.Item.Meta
              title={company.name}
              description={company.industry}
            />
          </List.Item>
        )}
      />
    </FetchContainer>
  );
};

export default CompaniesSmall;
