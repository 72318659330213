import { FetchError } from "@/utils/customFetch";
import { useMe } from "@/utils/hooks/useMe";
import { useParams, useRouter } from "next/navigation";
import { useEffect } from "react";

export function useStartup() {
  const { data, isPending, error } = useMe();
  const router = useRouter();
  const { companyId } = useParams();
  const userCompanyId = data?.payload.companyId;
  const isLoading =
    isPending ||
    (error instanceof FetchError && error.status === 401) ||
    (userCompanyId && userCompanyId !== companyId);

  useEffect(() => {
    if (userCompanyId == null) return;
    if (userCompanyId !== companyId) {
      router.replace(`/${userCompanyId}/dashboard`);
    }
  }, [router, companyId, userCompanyId]);

  return isLoading;
}
