"use client";
import PageLoader from "@/components/Loader/PageLoader";
import PageError from "@/components/Error/PageError";
import Companies from "@/modules/Companies";
import { useMe } from "@/utils/hooks/useMe";
import { useStartup } from "@/utils/hooks/useStartup";

export default function HomePage() {
  const { data: me, isError, refetch } = useMe();
  const isLoading = useStartup();

  if (isLoading) return <PageLoader />;

  if (isError) return <PageError refetch={refetch} />;

  if (me?.payload.permissions.includes("manage:all")) return <Companies />;

  return null;
}
