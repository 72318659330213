"use client";

import { Button, Typography } from "antd";
import { WarningOutlined } from "@ant-design/icons";

import styles from "./PageError.module.scss";

interface PageErrorProps {
  refetch: () => void;
}

const PageError = ({ refetch }: PageErrorProps) => {
  return (
    <div className={styles.container}>
      <WarningOutlined
        style={{
          fontSize: "100px",
          marginBottom: "20px",
        }}
      />

      <Typography.Title level={2} style={{ marginBottom: "8px" }}>
        Oops! An error occurred while connecting to the server.
      </Typography.Title>

      <Typography.Text style={{ fontSize: "20px", marginBottom: "30px" }}>
        Please try again, and if the issue persists, contact support.
      </Typography.Text>

      <Button
        type="primary"
        size="large"
        style={{ width: "100px" }}
        onClick={refetch}
      >
        Retry
      </Button>
    </div>
  );
};

export default PageError;
