import React, { useState } from "react";
import { Spin, theme, Table, Button } from "antd";
import { useQuery } from "@tanstack/react-query";
import Link from "next/link";

import api from "@/utils/api";
import { CompaniesResponse } from "@/utils/types/response/company";
import { SingleCompany } from "@/utils/types/response/company";
import { pageSizeOptions, defaultPageSize } from "@/utils/constants";

import FetchContainer from "@/components/FetchContainer/FetchContainer";

export const columns = [
  {
    title: "Name",
    dataIndex: "name",
  },
  {
    title: "Industry",
    dataIndex: "industry",
  },
  {
    title: "Action",
    dataIndex: "",
    width: 100,
    render: (_: unknown, company: SingleCompany) => (
      <Link
        href={`/${company.id}/dashboard`}
        passHref
        onClick={(e) => e.stopPropagation()}
      >
        <Button type="link" style={{ paddingLeft: 0 }}>
          Go to Portal
        </Button>
      </Link>
    ),
  },
];

const CompaniesLarge = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(defaultPageSize);

  const {
    data: companies,
    isPending,
    isError,
    refetch,
    isPlaceholderData,
  } = useQuery<CompaniesResponse>({
    queryKey: ["companies", page, pageSize],
    queryFn: () => api.company.getCompanies(page, pageSize),
    placeholderData: (prevData) => prevData,
  });

  return (
    <FetchContainer
      isPending={isPending}
      isError={isError}
      noData={!companies?.data.length}
      retry={() => refetch()}
    >
      <Spin spinning={isPlaceholderData}>
        <Table
          style={{
            padding: 16,
            background: colorBgContainer,
            border: "1px solid #f0f0f0",
            borderRadius: "1rem",
          }}
          showHeader={false}
          scroll={{ x: "max-content" }}
          rowKey={(record) => record.id}
          columns={columns}
          dataSource={companies?.data}
          pagination={{
            current: page,
            responsive: true,
            total: companies?.totalItems,
            pageSize: companies?.pageSize,
            showSizeChanger: true,
            pageSizeOptions: pageSizeOptions,
            onChange: (pageNumber) => {
              setPage(pageNumber);
            },
            onShowSizeChange: (_, pageSize) => {
              setPageSize(pageSize);
            },
            showTotal: (total, range) => {
              return `${range[0]}-${range[1]} of ${total} items`;
            },
          }}
        />
      </Spin>
    </FetchContainer>
  );
};

export default CompaniesLarge;
