"use client";
import React from "react";
import { theme, Grid } from "antd";

import CompaniesLarge from "./_components/CompaniesLarge";
import CompaniesSmall from "./_components/CompaniesSmall";

const { useBreakpoint } = Grid;

const Companies = () => {
  const screens = useBreakpoint();

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const renderCompanies = () => {
    if (Object.keys(screens).length === 0) return;

    if (screens.md) {
      return <CompaniesLarge />;
    }

    return <CompaniesSmall />;
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        padding: screens.xl ? "10rem" : "5rem 2rem",
        background: colorBgContainer,
      }}
    >
      {renderCompanies()}
    </div>
  );
};

export default Companies;
